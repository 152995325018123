import axios from 'axios';

import clientConfig from '../client-config';

export async function getHomeWorks() {
  let res;
  try {
    res = await axios.get(
      clientConfig.backendHOST + '/homework/StudenHomeworks'
    );
  } catch (e) {
    console.error(e);
  }
  if (res) {
    if (res.status === 200) {
      let tmpRes = [];
      res.data.map((item) => {
        tmpRes.push({
          id: item.id,
          jelenlet_id: item.jelenlet_id,
          created_at: item.created_at,
          created_by: item.created_by,
          id_diak: item.id_diak,
          homework_title: item.homework_title,
          homework_description: item.homework_description,
          homework_status: item.homework_status,
          homework_deadline: item.homework_deadline,
          overdue: item.overdue,
          file: [
            {
              source: item.homework_file,
              options: {
                type: 'local',
              },
            },
          ],
        });
      });
      return {
        isOk: true,
        data: tmpRes,
      };
    } else {
      return {
        isOk: false,
      };
    }
  } else {
    return {
      isOk: false,
    };
  }
}
