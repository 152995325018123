import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { getUserSchedules } from '../../api/schedules';
import './schedules.scss';

export default function Schedules() {
  const [data, setData] = useState({});

  useEffect(() => {
    async function apiCall() {
      const apiResponse = await getUserSchedules();
      if (apiResponse) {
        setData(apiResponse.data);
      }
    }
    apiCall();
  }, []);

  return (
    <React.Fragment>
      <div className='Schedules'>
        <table className='scheduleTable'>
          <thead>
            <tr>
              <th className='c1'>Időpont</th>
              <th className='c2'>Tanóra</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.length > 0 &&
              data.map((item, i) => {
                var timeMoment_from = moment(item.time_from, 'HH:mm:ss');
                var timeMoment_to = moment(item.time_to, 'HH:mm:ss');
                return (
                  <tr className={`rowCol${i % 2}`} key={i}>
                    <td className='d1'>
                      <p>
                        <Moment format='YYYY.MM.DD'>{item.datum}</Moment>{' '}
                        <Moment format='HH:mm'>{timeMoment_from}</Moment> -
                        <Moment format='HH:mm'>{timeMoment_to}</Moment>
                      </p>
                    </td>

                    <td className='d2'>
                      <p>
                        {item.tanora_code} {item.tantargy_descr}{' '}
                        {item.invoice_nr}
                      </p>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
}
