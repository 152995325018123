import 'devextreme-react/text-area';
import React, { useEffect, useState } from 'react';

import { locale } from 'devextreme/localization';
import { IntlProvider } from 'react-intl';
import './homework.scss';

import { getHomeWorks } from '../../api/homework';
import HomeWorkItem from '../../components/Homework/HomeWorkItem';
import HomeWorkReplyItem from '../../components/Homework/HomeWorkReplyItem';

export default function Homework() {
  locale(navigator.language);
  const [homeworkData, setHomeworkData] = useState([]);

  useEffect(() => {
    async function apiCall() {
      const apiResponse = await getHomeWorks();
      if (apiResponse) {
        setHomeworkData(apiResponse.data);
      }
    }
    apiCall();
  }, []);

  return (
    <IntlProvider locale='hu' defaultLocale='hu'>
      <React.Fragment>
        <h4 className={'content-block'}>Házi feladatok</h4>
        <div className='dx-viewport homework-list'>
          {homeworkData &&
            homeworkData.map((hw) => {
              console.log(hw.homework_status);
              return (
                <div
                  className={`homework-items ${
                    hw.homework_status === 50
                      ? 'solved'
                      : hw.overdue
                      ? 'overdue'
                      : 'in-time'
                  }`}
                  key={hw.id}
                >
                  <div className='homework-task'>
                    <HomeWorkItem
                      id={hw.id}
                      file={hw.file}
                      title={hw.homework_title}
                      description={hw.homework_description}
                      diak_id={hw.id_diak}
                      createad_at={hw.created_at}
                      status={hw.homework_status}
                      deadline={hw.homework_deadline}
                      jelenlet_id={hw.jelenlet_id}
                    />
                  </div>
                  <div className='homework-reply'>
                    <HomeWorkReplyItem hw_id={hw.id} />
                    {hw.homework_status === 50 ? (
                      <div className='hw-reply-text'>
                        <p>Már sikeresen beküldted a házi feladatot!</p>
                        <p>Ha szeretnél még beküldeni van rá lehetőséged</p>
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })}
        </div>
      </React.Fragment>
    </IntlProvider>
  );
}
