import React, { useState } from 'react';

import 'filepond/dist/filepond.min.css';
import { FilePond, registerPlugin } from 'react-filepond';

import FilePondPluginGetFile from 'filepond-plugin-get-file';
import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css';
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview';
import FilePondPluginPdfPreview from 'filepond-plugin-pdf-preview';

import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import { backendHOST } from '../../client-config';
import './HomeworksItem.scss';

registerPlugin(
  FilePondPluginMediaPreview,
  FilePondPluginPdfPreview,
  FilePondPluginGetFile,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode
);

const url = backendHOST + '/homework/reply';

export default function HomeWorkReplyItem({ hw_id }) {
  const [descr, setDescr] = useState('Mellékelten küldöm a megoldásokat');
  const [files, setFiles] = useState([]);

  const serverSettings = {
    url: url,
    process: {
      headers: {
        'x-auth-token': localStorage.token,
        'Access-Control-Allow-Origin': '*',
      },
      onload: (response) => response.key,
      onerror: (error) => error.message,
      ondata: (formData) => {
        // Add additional data to the form data
        formData.append('id_homework', hw_id);
        formData.append('reply_description', descr);
        return formData;
      },
    },
  };
  const [diak, setDiak] = useState({});

  return (
    <div className='dx-row-padding homework-item row'>
      <React.Fragment>
        <div className='hw-column main'>
          <p>
            <strong>Feladat visszaküldése</strong>
          </p>

          <FilePond
            files={files}
            onupdatefiles={setFiles}
            allowDownloadByUrl={false}
            allowImagePreview={true}
            allowDrop={true}
            allowRevert={false}
            allowRemove={false}
            filePosterHeight={160}
            disabled={false}
            styleButtonRemoveItemPosition='right'
            maxFiles={5}
            server={serverSettings}
            name='files' /* sets the file input name, it's filepond by default */
            labelIdle='Húzd ide a megoldott feladatokat vagy <span class="filepond--label-action">töltsd fel</span> a gépedről'
          />
        </div>
      </React.Fragment>
    </div>
  );
}
