import {
  Column,
  DataGrid,
  GroupItem,
  Grouping,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid';
import React, { useEffect, useState } from 'react';
import { getUserBalanceDetails } from '../../api/balance';
import './BalanceBoard.scss';

export default function BalanceBoard({ onUserBalanceChanged }) {
  const [balanceData, setData] = useState({});

  //  const [balance, setBalance] = useState(0);
  var balance = 0;
  useEffect(() => {
    async function apiCall() {
      const apiResponse = await getUserBalanceDetails();
      if (apiResponse) {
        setData(apiResponse.data);
      }
    }
    apiCall();
  }, []);

  function rowPrepared(e) {
    if (e.rowType === 'data') {
      if (e.data.rowColor === 'blBlue') {
        e.rowElement.style.backgroundColor = 'rgb(202, 221, 244)';
      }
      if (e.data.rowColor === 'blGreen') {
        e.rowElement.style.backgroundColor = 'rgb(235, 255, 235)';
      }
      if (e.data.rowColor === 'blRed') {
        e.rowElement.style.backgroundColor = 'rgb(253, 228, 219)';
      }
      if (e.data.rowColor === 'blRed2') {
        e.rowElement.style.backgroundColor = 'rgb(251, 193, 193)';
      }
    }
  }
  /*
         <table className='balanceTable'>
          <tr>
            <th className='c1'>Típus</th>
            <th className='c2'>Dátum</th>
            <th className='c3'>Megjegyzés</th>
            <th className='c4'>Állapot</th>
            <th className='c5'>Összeg</th>
            <th className='c6'>Egyenleg változás</th>
          </tr>
          {balanceData &&
            balanceData.length > 0 &&
            balanceData.map((item) => {
              if (item.oraStatus !== 'OPEN') {
                balance += item.osszeg;
                onUserBalanceChanged(balance);
              }
              return (
                <BalanceItem itemData={item} balance={balance}></BalanceItem>
              );
            })}
        </table>
        

*/
  return (
    <React.Fragment>
      <div className='balanceBoard'>
        <DataGrid
          id='balanceGrid'
          dataSource={balanceData}
          keyExpr='tanev'
          key='tanev'
          showBorders={false}
          hoverStateEnabled={true}
          columnAutoWidth={true}
          rowAlternationEnabled={false}
          allowColumnReordering={false}
          paging={false}
          onRowPrepared={rowPrepared}
        >
          <Grouping autoExpandAll={false} />
          <Column
            className='balanceData'
            dataField='tanev'
            caption='Tanév'
            groupIndex={1}
            allowSorting={false}
          ></Column>
          <Column
            dataField='tipusDescr'
            caption='Mozgásnem'
            width={100}
            className='balanceData'
            allowSorting={false}
          ></Column>
          <Column
            dataField='datum'
            caption='Dátum'
            displayFormat='yyyy.MM.dd'
            editorType='dxDateBox'
            dataType='date'
            width={120}
            allowSorting={false}
          ></Column>

          <Column
            dataField='time_disp'
            caption=''
            width={150}
            allowSorting={false}
          ></Column>

          <Column
            dataField='posDescr'
            caption='Tanóra'
            allowSorting={false}
          ></Column>

          <Column
            dataField='oraStatusDescr'
            caption='Állapot'
            allowSorting={false}
          ></Column>

          <Column
            dataField='osszeg'
            caption='Összeg'
            allowSorting={false}
          ></Column>
          <Column
            dataField='balance'
            caption='Egyenleg változás'
            allowSorting={false}
          ></Column>
          <Summary>
            <GroupItem
              column='osszeg'
              summaryType='sum'
              alignByColumn
              displayFormat='{0} Ft'
            />
            <TotalItem
              column='osszeg'
              summaryType='sum'
              displayFormat='Egyenleg: {0} Ft'
            />
          </Summary>
        </DataGrid>
      </div>
    </React.Fragment>
  );
}
