import axios from 'axios';
import moment from 'moment';
import clientConfig from '../client-config';
import setAuthToken from '../utils/setAuthToken';

export async function getUserSchedules() {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  if (localStorage.token) {
    let res;
    try {
      res = await axios.get(clientConfig.backendHOST + '/reports/userSchedule');
    } catch (e) {
      console.error(e);
    }

    if (res) {
      if (res.status === 200) {
        return {
          isOk: true,
          data: res.data,
        };
      }
      if (res.status === 403) {
        localStorage.removeItem('token');
        return {
          isOk: false,
        };
      } else {
        return {
          isOk: false,
        };
      }
    } else {
      return {
        isOk: false,
      };
    }
  } else {
    return {
      isOk: false,
    };
  }
}

export async function getUserScheduleAll() {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  if (localStorage.token) {
    let res;
    try {
      res = await axios.get(
        clientConfig.backendHOST + '/reports/userScheduleAll'
      );
    } catch (e) {
      console.error(e);
    }

    var CalendarItems = [];
    if (res.data.length > 0) {
      res.data.map((it) => {
        CalendarItems.push({
          text: it.tanora_code + ' ' + it.tantargy_descr,
          startDate: moment(it.datum.slice(0, 10) + 'T' + it.time_from),
          endDate: moment(it.datum.slice(0, 10) + 'T' + it.time_to),
          jelenlet_id: it.jelenlet_id,
          status: it.oraStatus,
          disabled: !it.lemondhato,
        });
      });
    }

    if (res) {
      if (res.status === 200) {
        return {
          isOk: true,
          data: CalendarItems,
        };
      }
      if (res.status === 403) {
        localStorage.removeItem('token');
        return {
          isOk: false,
        };
      } else {
        return {
          isOk: false,
        };
      }
    } else {
      return {
        isOk: false,
      };
    }
  } else {
    return {
      isOk: false,
    };
  }
}

export async function cancelTanora(tanoraDetails) {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  if (localStorage.token) {
    let res;
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify(tanoraDetails);
    try {
      res = await axios.post(
        clientConfig.backendHOST + '/schedule/cancelTanora',
        body,
        config
      );
    } catch (e) {
      console.error(e);
    }

    if (res) {
      if (res.status === 200) {
        return {
          isOk: true,
        };
      }
    } else {
      return {
        isOk: false,
      };
    }
  } else {
    return {
      isOk: false,
    };
  }
}

export async function getOktatokToDiak() {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  if (localStorage.token) {
    let res;
    try {
      res = await axios.get(
        clientConfig.backendHOST + '/schedule/getOktatokToDiak'
      );
    } catch (e) {
      console.error(e);
    }

    if (res) {
      if (res.status === 200) {
        return {
          isOk: true,
          data: res.data,
        };
      }
      if (res.status === 403) {
        localStorage.removeItem('token');
        return {
          isOk: false,
        };
      } else {
        return {
          isOk: false,
        };
      }
    } else {
      return {
        isOk: false,
      };
    }
  } else {
    return {
      isOk: false,
    };
  }
}
