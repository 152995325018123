import React, { useContext, useMemo } from 'react';

import Right from '../assets/icons/Right';
import Wrong from '../assets/icons/Wrong';

import { QuizContext } from '../store/QuizContext';

const Answer = () => {
  const { checkAnswer } = useContext(QuizContext);
  return useMemo(() => {
    return (
      <div className='answer'>
        <button className='right' onClick={() => checkAnswer(true)}>
          <Right />
        </button>
        <button className='wrong' onClick={() => checkAnswer(false)}>
          <Wrong />
        </button>
      </div>
    );
  }, [checkAnswer]);
};

export default Answer;
