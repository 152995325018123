import React, { useEffect, useState } from 'react';
import { FormattedNumber, IntlProvider } from 'react-intl';
import { getUserBalance, getUserInvoice } from '../../api/balance';
import InvoiceLink from '../Penzugyek/InvoiceLink';
import './BalanceBox.scss';

export default function BalanceBox({}) {
  const [data, setData] = useState({});

  const [invoiceData, setInvoiceData] = useState([]);

  useEffect(() => {
    async function apiCall() {
      const apiResponse = await getUserBalance();
      if (apiResponse) {
        setData(apiResponse.data);
      }

      const apiInvoiceData = await getUserInvoice();
      if (apiInvoiceData) {
        setInvoiceData(apiInvoiceData.data);
      }
    }
    apiCall();
  }, []);

  return (
    <React.Fragment>
      <IntlProvider locale='hu' defaultLocale='hu'>
        <div className='Balances'>
          {data && data[0] && (
            <p>
              A fiókodhoz tartozó nyitott egyenleg{' '}
              <strong>
                <FormattedNumber
                  value={data[0].Balance}
                  style='currency'
                  currency='HUF'
                  maximumSignificantDigits={2}
                />
              </strong>{' '}
              {data[0].Balance > 0 && (
                <strong className='clRed'>tartozás</strong>
              )}
              {data[0].Balance < 0 && (
                <strong className='clGreen'>túlfizetés</strong>
              )}
            </p>
          )}
        </div>
        <div className='invoices'>
          {invoiceData && (
            <p>
              <strong>Nyitott számláim:</strong>
            </p>
          )}
          {invoiceData &&
            invoiceData.map((inv) => {
              console.log(inv);
              return <InvoiceLink invoiceNumber={inv.invoice_nr} />;
            })}
        </div>
      </IntlProvider>
    </React.Fragment>
  );
}
