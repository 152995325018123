import React from 'react';
import ScrollView from 'devextreme-react/scroll-view';
import './wide-card.scss';

export default function WideCard({ title, description, children }) {
  return (
    <ScrollView
      height={'100%'}
      width={'100%'}
      className={'with-footer wide-card'}
    >
      <div className={'dx-card content'}>
        <div className='header-container'>
          <div className='logo'>
            <img src={process.env.PUBLIC_URL + '/img/Logo.png'} />
          </div>
          <div className={'header'}>
            <div className={'title'}>{title}</div>
            <div className={'description'}>{description}</div>
          </div>
        </div>
        {children}
      </div>
    </ScrollView>
  );
}
