import React, { useCallback, useEffect, useState } from 'react';
import randomNumber from '../../../utils/randomNumber';

export const QuizContext = React.createContext();

export const QuizProvider = ({ children }) => {
  const [number1, setNumber1] = useState();
  const [number2, setNumber2] = useState();
  const [rightAnswer, setRightAnswer] = useState();
  const [twistedAnswer, setTwistedAnswer] = useState();
  const [score, setScore] = useState(0);
  const [gameFinished, setGameFinish] = useState(true);
  const [color, setColor] = useState('255, 255, 255');
  const [time, setTime] = useState(0);
  const [roundScore, setRoundScore] = useState(0);
  const [timeup, setTimeup] = useState(false);

  useEffect(() => {
    const sign = randomNumber(1, 2);
    const num1 = randomNumber(1, 30);
    const num2 = randomNumber(1, 30);
    const res = num1 + num2;
    const color = `${randomNumber(0, 256)}, ${randomNumber(
      0,
      256
    )}, ${randomNumber(0, 256)}`;

    setColor(color);
    setNumber1(num1);
    setNumber2(num2);
    setRightAnswer(res);
    setTwistedAnswer(() => {
      return sign === 1 ? res + randomNumber(0, 3) : res - randomNumber(0, 3);
    });
    setTimeup(false);
  }, [score, gameFinished, timeup]);

  const resetTime = useCallback(() => {
    setTime(0);
    setTimeup(true);
  }, []);

  const checkAnswer = useCallback(
    (userAnswer) => {
      const isRight = userAnswer === (rightAnswer === twistedAnswer);
      if (isRight) {
        setScore((s) => s + roundScore);
      } else {
        setGameFinish(true);
      }
      console.log('reset', time);
      resetTime();
    },
    [rightAnswer, twistedAnswer, resetTime]
  );

  const restart = useCallback(() => {
    setScore(0);
    setGameFinish(false);
  }, []);

  return (
    <QuizContext.Provider
      value={{
        number1,
        number2,
        twistedAnswer,
        score,
        checkAnswer,
        gameFinished,
        restart,
        color,
        time,
        setTime,
        resetTime,
        setRoundScore,
      }}
    >
      {children}
    </QuizContext.Provider>
  );
};
