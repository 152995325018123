import React from 'react'

const Restart = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
			className='feather feather-refresh-ccw'
		>
			<polyline points='1 4 1 10 7 10'></polyline>
			<polyline points='23 20 23 14 17 14'></polyline>
			<path d='M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15'></path>
		</svg>
	)
}

export default React.memo(Restart)
