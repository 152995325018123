import { Navigate, Route, Routes } from 'react-router-dom';
import {
  ChangePasswordForm,
  CreateAccountForm,
  LoginForm,
  ResetPasswordForm,
} from './components';
import { SingleCard } from './layouts';
import WideCard from './layouts/wide-card/wide-card';

export default function UnauthenticatedContent() {
  return (
    <Routes>
      <Route
        path='/login'
        element={
          <SingleCard
            title='Belépés'
            description={'a Scriptantia adminisztrációs felületre'}
          >
            <LoginForm />
          </SingleCard>
        }
      />
      <Route
        path='/jelentkezes'
        element={
          <WideCard
            title='Jelentkezés'
            description={'Scriptanta foglalkozásokra'}
          >
            <CreateAccountForm />
          </WideCard>
        }
      />
      <Route
        path='/reset-password'
        element={
          <SingleCard
            title='Elfelejtett jelszó visszaállítása'
            description='Add meg az e-mail címed melyet a regisztrációkor használtál és levélben elküldjük az új jelszó beállításáshoz szükséges adatokat.'
          >
            <ResetPasswordForm />
          </SingleCard>
        }
      />
      <Route
        path='/change-password/:recoveryCode'
        element={
          <SingleCard title='Change Password'>
            <ChangePasswordForm />
          </SingleCard>
        }
      />
      <Route path='*' element={<Navigate to={'/login'} />}></Route>
    </Routes>
  );
}
