import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { getDBVersion } from './api/version';
import appInfo from './app-info';
import routes from './app-routes';
import { Footer } from './components';
import { SideNavOuterToolbar as SideNavBarLayout } from './layouts';

export default function Content() {
  const [dbType, setDBType] = useState('U');
  const [dbVersion, setDBVersion] = useState(0);
  useEffect(() => {
    getDBVersion().then((res) => {
      if (res.isOk) {
        setDBType(res.data.environement);
        setDBVersion(res.data.version);
      }
    });
  }, []);

  return (
    <SideNavBarLayout title={`${appInfo.title}-(${dbType}-${dbVersion})`}>
      <Routes>
        {routes.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
        <Route path='*' element={<Navigate to='/home' />} />
      </Routes>
      <Footer>
        Copyright © 2011-{new Date().getFullYear()} {appInfo.title} Inc.
        <br />
        All trademarks or registered trademarks are property of their respective
        owners.
      </Footer>
    </SideNavBarLayout>
  );
}
