import React, { useMemo } from 'react';

import Restart from '../assets/icons/Restart';
import { QuizContext } from '../store/QuizContext';

const Result = () => {
  const { score, restart } = React.useContext(QuizContext);
  return useMemo(
    () => (
      <div className='quiz_container result'>
        <h3>Fejszámoló!</h3>
        <h1>
          mai pontszámod <span>{score}</span>
        </h1>
        <button className='restart' onClick={restart}>
          <Restart />
        </button>
      </div>
    ),
    [score, restart]
  );
};

export default Result;
