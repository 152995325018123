import 'devextreme-react/text-area';
import React, { useEffect, useState } from 'react';

import { locale } from 'devextreme/localization';
import { FormattedNumber, IntlProvider } from 'react-intl';
import { getUserToEdit } from '../../api/profile';
import BalanceBoard from '../../components/Penzugyek/BalanceBoard';
import './balance.scss';

export default function Balance() {
  locale(navigator.language);

  const [userData, setUserData] = useState({});
  const [BalanceValue, setBalance] = useState(0);

  useEffect(() => {
    async function apiCall() {
      const apiResponse = await getUserToEdit();
      if (apiResponse) {
        setUserData(apiResponse.data);
      }
    }
    apiCall();
  }, []);
  return (
    <IntlProvider locale='hu' defaultLocale='hu'>
      <React.Fragment>
        <h2 className={'content-block'}>Egyenleginfó</h2>

        <div className={'content-block dx-card responsive-paddings'}>
          {userData && (
            <div>
              <p>
                <strong>
                  {userData.lastName} {userData.firstName}
                </strong>
              </p>
              <p>
                Aktuális egyenleged:{' '}
                <FormattedNumber
                  value={BalanceValue}
                  style='currency'
                  currency='HUF'
                  maximumSignificantDigits={2}
                />{' '}
                {BalanceValue >= 0 ? 'tartozás' : 'túlfizetés'}
              </p>
            </div>
          )}
        </div>
        <div className={'content-block dx-card responsive-paddings'}>
          <BalanceBoard onUserBalanceChanged={setBalance} />
        </div>
      </React.Fragment>
    </IntlProvider>
  );
}
