export const homeMenu = [
  {
    text: 'Főoldal',
    path: '/home',
    icon: 'home',
  },
  {
    text: 'Adataim',
    path: '/profile',
    icon: 'user',
  },
];

export const peldatarMenu = [
  {
    text: 'Példatárak',
    icon: 'folder',
    path: '/peldatarak',
  },
];

export const penzugyekMenu = [
  {
    text: 'Pénzügyek',
    icon: 'money',
    path: '/balance',
  },
];

export const jelenletiOktatasMenu = [
  {
    text: 'Tanórák',
    icon: 'event',
    path: '/schedule',
  },
  {
    text: 'Házi feladatok',
    icon: 'checklist',
    path: '/hazi-feladat',
  },
];
