import React from 'react';
import { useNavigate } from 'react-router-dom';
import DailyRoutines from '../../components/DailyRoutines/DailyRoutines';
import { QuizProvider } from '../../components/DailyRoutines/store/QuizContext';
import BalanceBox from '../../components/dashboardItems/BalanceBox';
import Schedules from '../../components/dashboardItems/schedules';
import { useAuth } from '../../contexts/auth';
import './home.scss';

export default function Home() {
  const { user } = useAuth();
  const navigate = useNavigate();

  function goToPenzugyek(e) {
    navigate('/balance');
  }

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Szia {user.user.firstName}!</h2>
      <div className={'content-block'}>
        <div className={'home-boxes dx-card responsive-paddings'}>
          <div className='home-box dx-card '>
            <p className='box-title'>Tervezett óráim</p>
            <Schedules />
          </div>
          <div className='home-box dx-card ' onClick={goToPenzugyek}>
            <p className='box-title'>Egyenlegem</p>
            <BalanceBox />
          </div>
        </div>

        <div className={'dx-card responsive-paddings'}>
          <div>
            <h4>Napi feladatok</h4>
          </div>
          <div className='daily-exercise-box'>
            <QuizProvider>
              <DailyRoutines />
            </QuizProvider>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
