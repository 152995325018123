import axios from 'axios';
import clientConfig from '../client-config';
import setAuthToken from '../utils/setAuthToken';

export async function getUserToEdit() {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  if (localStorage.token) {
    let res;
    try {
      res = await axios.get(clientConfig.backendHOST + '/auth/User');
    } catch (e) {
      console.error(e);
    }

    if (res) {
      if (res.status === 200) {
        return {
          isOk: true,
          data: res.data,
        };
      }
      if (res.status === 403) {
        localStorage.removeItem('token');
        return {
          isOk: false,
        };
      } else {
        return {
          isOk: false,
        };
      }
    } else {
      return {
        isOk: false,
      };
    }
  } else {
    return {
      isOk: false,
    };
  }
}

export async function getUserProfileToEdit() {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  if (localStorage.token) {
    let res;
    try {
      res = await axios.get(clientConfig.backendHOST + '/auth/userProfile');
    } catch (e) {
      console.error(e);
    }

    if (res) {
      if (res.status === 200) {
        return {
          isOk: true,
          data: res.data,
        };
      }
      if (res.status === 403) {
        localStorage.removeItem('token');
        return {
          isOk: false,
        };
      } else {
        return {
          isOk: false,
        };
      }
    } else {
      return {
        isOk: false,
      };
    }
  } else {
    return {
      isOk: false,
    };
  }
}
