import React from 'react';
import './DailyRoutines.scss';

import Quiz from './components/Quiz';
import Result from './components/Result';

import { QuizContext } from './store/QuizContext';

function DailyRoutines() {
  const { gameFinished } = React.useContext(QuizContext);
  return (
    <div className='container'>{gameFinished ? <Result /> : <Quiz />}</div>
  );
}

export default DailyRoutines;
