import axios from 'axios';
import clientConfig from '../client-config';
import setAuthToken from '../utils/setAuthToken';

export async function signIn(loginData) {
  try {
    // Send request

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify(loginData);
    const res = await axios.post(
      clientConfig.backendHOST + '/auth',
      body,
      config
    );

    if (res.status === 200) {
      if (res.data.token) {
        setAuthToken(res.data.token);
      }
      return {
        isOk: true,
        data: res.data,
      };
    }
  } catch {
    return {
      isOk: false,
      message: 'Authentication failed',
    };
  }
}

export async function getUser() {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  if (localStorage.token) {
    let res;
    try {
      res = await axios.get(clientConfig.backendHOST + '/auth');
    } catch (e) {
      console.error(e);
    }

    if (res) {
      if (res.status === 200) {
        return {
          isOk: true,
          data: res.data,
        };
      }
      if (res.status === 403) {
        localStorage.removeItem('token');
        return {
          isOk: false,
        };
      } else {
        return {
          isOk: false,
        };
      }
    } else {
      return {
        isOk: false,
      };
    }
  } else {
    return {
      isOk: false,
    };
  }
}

export async function createAccount(data) {
  try {
    // Send request
    console.log(data);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to create account',
    };
  }
}

export async function registerStudent(data) {
  try {
    // Send request
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify(data);
    console.log(body);
    const res = await axios.post(
      clientConfig.backendHOST + '/auth/registerStudent',
      body,
      config
    );

    if (res.status === 200) {
      return {
        isOk: true,
      };
    }
  } catch {
    return {
      isOk: false,
      message: 'Nem sikerült beküldeni, kérlek frissítsd az oldalt!',
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request
    console.log(email, recoveryCode);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to change password',
    };
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to reset password',
    };
  }
}

export async function getDiakById(idDiak) {
  // Send request
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (localStorage.token) {
    let res;
    try {
      res = await axios.get(
        clientConfig.backendHOST + '/userData/user',
        {
          params: {
            id: idDiak,
          },
        },
        config
      );
    } catch (e) {
      console.error('error', e);
    }

    if (res) {
      if (res.status === 200) {
        return res.data;
      }
    } else {
      return {
        isOk: false,
      };
    }
  } else {
    return {
      isOk: false,
    };
  }
}
