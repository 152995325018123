import React, { useContext, useMemo } from 'react';

import { QuizContext } from '../store/QuizContext';

const Quiz = () => {
  const { number1, number2, twistedAnswer } = useContext(QuizContext);
  return useMemo(() => {
    return (
      <p className='question'>
        <span>{number1}</span>
        {'+'}
        <span>{number2}</span>
        {'='}
        <span>{twistedAnswer}</span>
      </p>
    );
  }, [number1, number2, twistedAnswer]);
};

export default Quiz;
