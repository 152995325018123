import axios from 'axios';
import moment from 'moment';
import clientConfig from '../client-config';
import setAuthToken from '../utils/setAuthToken';

export async function getUserBalanceDetails() {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  if (localStorage.token) {
    let res;
    try {
      res = await axios.get(
        clientConfig.backendHOST + '/reports/userBalanceDetails'
      );
    } catch (e) {
      console.error(e);
    }

    if (res) {
      if (res.status === 200) {
        let tmpResult = [];
        let tmpBalance = 0;
        if (res.data.length > 0) {
          res.data.map((item) => {
            if (item.oraStatus !== 'OPEN') {
              tmpBalance += item.osszeg;
            }
            let tmpOraStatusDescr = '';
            let tmpTipusDescr = '';
            let tmpRowColor = '';
            switch (item.tipus) {
              case 'TANORA':
                tmpTipusDescr = 'Tanóra';
                tmpRowColor = 'blBlue';
                break;
              case 'BEFIZET':
                tmpTipusDescr = 'Befizetés';
                tmpRowColor = 'blGreen';

                break;
              case 'KEDVEZMENY':
                tmpTipusDescr = 'Kedvezmény';
                tmpRowColor = 'blGreen';

                break;
              default:
                break;
            }
            switch (item.oraStatus) {
              case 'OK':
                tmpOraStatusDescr = '';
                break;
              case 'LEMOND':
                tmpOraStatusDescr = 'Lemondott';
                tmpRowColor = 'blRed';
                break;
              case 'NEMSZ':
                tmpOraStatusDescr = 'Nem jött';
                tmpRowColor = 'blRed2';
                break;
              case 'OPEN':
                tmpOraStatusDescr = 'Nyitott';

                break;
              default:
                break;
            }

            var timeMoment_from = moment(item.time_from, 'HH:mm:ss');
            var timeMoment_to = moment(item.time_to, 'HH:mm:ss');

            tmpResult.push({
              tipus: item.tipus,
              tipusDescr: tmpTipusDescr !== '' ? tmpTipusDescr : item.tipus,
              datum: moment(new Date(item.datum), 'YYYY.MM.DD'),
              time_from: item.time_from,
              time_to: item.time_to,
              time_disp: timeMoment_from.isValid()
                ? timeMoment_from.format('HH:mm:ss') +
                  '-' +
                  timeMoment_to.format('HH:mm:ss')
                : '',
              time_to_disp: timeMoment_to.format('HH:mm:ss'),
              tanora_code: item.tanora_code,
              tantargy_descr: item.tantargy_descr,
              posDescr: item.tantargy_descr.startsWith('Automatik')
                ? item.tanora_code + ' ' + item.tantargy_descr
                : item.tanora_code +
                  ' ' +
                  item.tantargy_descr +
                  ' ' +
                  item.invoice_nr,
              lemondhato: item.lemondhato,
              osszeg: item.oraStatus !== 'OPEN' ? item.osszeg : 0,
              invoice_nr: item.invoice_nr,
              oraStatus: item.oraStatus,
              oraStatusDescr: tmpOraStatusDescr,
              jelenlet_id: item.jelenlet_id,
              tanev: item.tanev,
              balance: tmpBalance,
              rowColor: tmpRowColor,
            });
          });
        }

        return {
          isOk: true,
          data: tmpResult, //res.data,
        };
      }
      if (res.status === 403) {
        localStorage.removeItem('token');
        return {
          isOk: false,
        };
      } else {
        return {
          isOk: false,
        };
      }
    } else {
      return {
        isOk: false,
      };
    }
  } else {
    return {
      isOk: false,
    };
  }
}

export async function getUserBalance() {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  if (localStorage.token) {
    let res;
    try {
      res = await axios.get(clientConfig.backendHOST + '/reports/userBalance');
    } catch (e) {
      console.error(e);
    }

    if (res) {
      if (res.status === 200) {
        return {
          isOk: true,
          data: res.data,
        };
      }
      if (res.status === 403) {
        localStorage.removeItem('token');
        return {
          isOk: false,
        };
      } else {
        return {
          isOk: false,
        };
      }
    } else {
      return {
        isOk: false,
      };
    }
  } else {
    return {
      isOk: false,
    };
  }
}

export async function getUserInvoice() {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  if (localStorage.token) {
    let res;
    try {
      res = await axios.get(clientConfig.backendHOST + '/reports/userInvoice');
    } catch (e) {
      console.error(e);
    }

    if (res) {
      if (res.status === 200) {
        return {
          isOk: true,
          data: res.data,
        };
      }
      if (res.status === 403) {
        localStorage.removeItem('token');
        return {
          isOk: false,
        };
      } else {
        return {
          isOk: false,
        };
      }
    } else {
      return {
        isOk: false,
      };
    }
  } else {
    return {
      isOk: false,
    };
  }
}
