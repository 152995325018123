import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';

export async function getInvoiceDetails() {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  if (localStorage.token) {
    let res;
    try {
      res = await axios.get(
        'https://scriptantialearning.azurewebsites.net//api/scriptantiaAdmin/getInvoice'
      );
    } catch (e) {
      console.error(e);
    }

    if (res) {
      if (res.status === 200) {
        return {
          isOk: true,
          data: res.data,
        };
      }
      if (res.status === 403) {
        localStorage.removeItem('token');
        return {
          isOk: false,
        };
      } else {
        return {
          isOk: false,
        };
      }
    } else {
      return {
        isOk: false,
      };
    }
  } else {
    return {
      isOk: false,
    };
  }
}
