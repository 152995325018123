import React from 'react';
import './Quiz.scss';

import Answer from './Answer';
import Question from './Question';
import Score from './Score';
import Timer from './Timer';

const Quiz = () => {
  return (
    <>
      <p className='logo'>Fejszámoló</p>

      <div className='quiz_container'>
        <Timer />
        <Question />
        <Answer />
      </div>
      <Score />
    </>
  );
};

export default Quiz;
