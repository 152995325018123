import Form, { GroupItem, Item, Label } from 'devextreme-react/form';
import React, { useEffect, useState } from 'react';
import 'devextreme-react/text-area';
import Button from 'devextreme-react/button';

import './profile.scss';
import { getUserProfileToEdit, getUserToEdit } from '../../api/profile';
import { locale } from 'devextreme/localization';

export default function Profile() {
  locale(navigator.language);

  const [userData, setUserData] = useState({});
  const [userProfile, setUserProfile] = useState({});
  const [displayEditData, toggleEditData] = useState(false);

  useEffect(() => {
    async function apiCall() {
      const apiResponse = await getUserToEdit();
      if (apiResponse) {
        setUserData(apiResponse.data);
      }
      const apiResponse2 = await getUserProfileToEdit();
      if (apiResponse2) {
        setUserProfile(apiResponse2.data);
      }
    }
    apiCall();
  }, []);
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Felhasználói adataim</h2>

      <div className={'content-block dx-card responsive-paddings'}>
        <div className={'form-avatar'}>
          <img alt={''} src={''} />
        </div>

        {userData && (
          <div>
            <div>
              <p>
                <strong>
                  {userData.lastName} {userData.firstName}
                </strong>
                {' / '}
                {userData.email}
              </p>
              {userProfile && (
                <p>
                  <strong>Számlázási cím: </strong>
                  {userProfile.zip} {userProfile.city} {userProfile.address}
                </p>
              )}
            </div>
            <div>
              <Button
                icon='preferences'
                type='normal'
                onClick={() => toggleEditData(!displayEditData)}
              ></Button>
            </div>
          </div>
        )}
      </div>
      {displayEditData && (
        <div className={'content-block dx-card responsive-paddings'}>
          <div>
            <Form
              id={'form'}
              formData={userData}
              labelLocation={'top'}
              colCount={1}
            >
              <GroupItem colCount={4} caption={`Alapadatok`}>
                <Item dataField='lastName' colSpan={2}>
                  <Label text={'Keresztnév'}></Label>
                </Item>
                <Item dataField='firstName' colSpan={2}>
                  <Label text={'Vezetéknév'}></Label>
                </Item>
                <Item dataField='email' colSpan={4}>
                  <Label text={'e-Mail cím'}></Label>
                </Item>
              </GroupItem>
            </Form>
            <Form
              id={'formProf'}
              formData={userProfile}
              labelLocation={'top'}
              colCount={1}
            >
              <GroupItem colCount={6} caption={`Számlázási adataim`}>
                <Item dataField='zip' colSpan={1}>
                  <Label text={'Irányítószám'}></Label>
                </Item>
                <Item dataField='city' colSpan={2}>
                  <Label text={'Város'}></Label>
                </Item>
                <Item dataField='address' colSpan={3}>
                  <Label text={'Cím'}></Label>
                </Item>
              </GroupItem>
              <GroupItem colCount={6} caption={`Egyéb adataim`}>
                <Item dataField='birthday' colSpan={2} editorType='dxDateBox'>
                  <Label text={'Szülinapom'}></Label>
                </Item>
                <Item dataField='phone' colSpan={2}>
                  <Label text={'Telefonszámom'}></Label>
                </Item>
                <Item dataField='school' colSpan={2}>
                  <Label text={'Iskolám'}></Label>
                </Item>
                <Item dataField='parentName' colSpan={3}>
                  <Label text={'Szüleim neve'}></Label>
                </Item>
                <Item dataField='phone2' colSpan={3}>
                  <Label text={'Szüleim telefonszáma'}></Label>
                </Item>
                <Item dataField='aboutme' colSpan={6} editorType='dxTextArea'>
                  <Label text={'Pár gondolat rólam'}></Label>
                </Item>
              </GroupItem>
              <GroupItem colCount={6} caption={`Egyéb adataim`}>
                <Item dataField='instagram' colSpan={3}>
                  <Label text={'Instagram linkem'}></Label>
                </Item>
                <Item dataField='facebook' colSpan={3}>
                  <Label text={'Facebook profilom'}></Label>
                </Item>
                <Item dataField='youtube' colSpan={3}>
                  <Label text={'Youtube csatornám'}></Label>
                </Item>
                <Item dataField='skype' colSpan={3}>
                  <Label text={'TikTok profilom'}></Label>
                </Item>
              </GroupItem>
            </Form>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
