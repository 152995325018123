import Scheduler from 'devextreme-react/scheduler';
import 'devextreme-react/text-area';
import { locale } from 'devextreme/localization';
import React, { useEffect, useState } from 'react';
import { cancelTanora, getUserScheduleAll } from '../../api/schedules';
import './schedule.scss';

export default function Schedule() {
  locale(navigator.language);

  const [data, setData] = useState({});
  const views = [];

  useEffect(() => {
    async function apiCall() {
      const apiResponse = await getUserScheduleAll();
      if (apiResponse) {
        setData(apiResponse.data);
      }
    }
    apiCall();
  }, []);

  function tanoraTorol(e) {
    cancelTanora({ jelenlet_id: e.appointmentData.jelenlet_id });
  }
  function openTanora(e) {
    if (e.appointmentData.status !== 'OPEN') {
      e.cancel = true;
    }
  }

  return (
    <React.Fragment>
      <div className={'content-block'}>
        <h2>Órarendem</h2>

        <div className={'content-block dx-card responsive-paddings'}>
          <div>
            <p>
              Az órarendben a tanórák 5 nappal előre mondhatóak le, a régebbi
              órák illetve 5 napon belül nincs lehetőség lemondásra. Ha Mégis le
              szeretnéd mondani az órát vedd fel velünk a kapcsolatot.
            </p>
          </div>
          <div>
            <Scheduler
              timeZone='Hungary/Budapest'
              dataSource={data}
              views={views}
              defaultCurrentView='month'
              // defaultCurrentDate={currentDate}
              height={600}
              startDayHour={9}
              onAppointmentDeleted={tanoraTorol}
              onAppointmentClick={openTanora}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
