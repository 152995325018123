import React, { useEffect, useState } from 'react';
import { getInvoiceDetails } from '../../api/invoice';

export default function InvoiceLink({ invoiceNumber }) {
  const [data, setData] = useState({});

  useEffect(() => {
    async function apiCall() {
      const apiResponse = await getInvoiceDetails();
      if (apiResponse) {
        setData(apiResponse.data);
      }
    }
    apiCall();
  }, []);

  return (
    <React.Fragment>
      <p>{invoiceNumber}</p>
    </React.Fragment>
  );
  /**
   * 
   *  <a href='https://www.index.hu' target='_blank' rel='noopener noreferrer'>
        {invoiceNumber}
      </a>
      
   */
}
