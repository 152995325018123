import 'devextreme-react/text-area';
import React from 'react';

import { locale } from 'devextreme/localization';
import { IntlProvider } from 'react-intl';

export default function UnserConstruction() {
  locale(navigator.language);

  return (
    <IntlProvider locale='hu' defaultLocale='hu'>
      <React.Fragment>
        <h2 className={'content-block'}>
          Ez a rész még fejlesztés alatt van hamarosan elérhető...
        </h2>
      </React.Fragment>
    </IntlProvider>
  );
}
