import React, { useContext, useEffect } from 'react';
import { QuizContext } from '../store/QuizContext';

const Timer = () => {
  const { time, setTime, resetTime, setRoundScore } = useContext(QuizContext);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((t) => {
        if (t !== 100) {
          setRoundScore(100 - t);
          return t + 1;
        } else {
          resetTime();
          return 0;
        }
      });
    }, 40);

    return () => clearInterval(interval);
  }, [setTime, resetTime]);

  return (
    <>
      <div className='timer'>
        <div
          className='clock'
          style={{
            width: `${time}%`,
          }}
        ></div>
      </div>
    </>
  );
};

export default Timer;
