import { withNavigationWatcher } from './contexts/navigation';
import {
  BalancePage,
  HomePage,
  HomeworkPage,
  MessagesPage,
  ProfilePage,
  SchedulePage,
  TasksPage,
  UCPage,
} from './pages';

const routes = [
  {
    path: '/tasks',
    element: TasksPage,
  },
  {
    path: '/profile',
    element: ProfilePage,
  },
  {
    path: '/home',
    element: HomePage,
  },
  {
    path: '/balance',
    element: BalancePage,
  },
  {
    path: '/schedule',
    element: SchedulePage,
  },
  {
    path: '/hazi-feladat',
    element: HomeworkPage,
  },
  {
    path: '/peldatarak',
    element: UCPage,
  },
  {
    path: '/uzenetek',
    element: MessagesPage,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  };
});
