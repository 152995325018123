import 'devextreme-react/text-area';
import React, { useEffect, useState } from 'react';
import TextArea from 'devextreme-react/text-area';
import CheckBox from 'devextreme-react/check-box';
import { locale } from 'devextreme/localization';
import { IntlProvider } from 'react-intl';
import { getUserToEdit } from '../../api/profile';
import './messages.scss';
import Button from 'devextreme-react/button';
import { getOktatokToDiak } from '../../api/schedules';

export default function Messages() {
  locale(navigator.language);

  const [userData, setUserData] = useState({});
  const [oktatok, setOktatok] = useState([]);
  const [recipients, setRecipients] = useState({});
  const [messageTxt, setMessage] = useState();

  useEffect(() => {
    async function apiCall() {
      const apiResponse = await getUserToEdit();
      if (apiResponse) {
        setUserData(apiResponse.data);
      }
      const apiResponseOkt = await getOktatokToDiak();
      if (apiResponseOkt) {
        console.log(apiResponseOkt.data);
        setOktatok(apiResponseOkt.data);
      }
    }
    apiCall();
    console.log('1,', oktatok);
  }, []);

  function onTextAreaValueChanged(e) {
    setMessage(e.value);
  }
  function onMessageSend(e) {
    console.log(messageTxt);
  }

  function onRecipientsChanged(e) {
    console.log(e, e.element.key);
  }

  return (
    <IntlProvider locale='hu' defaultLocale='hu'>
      <React.Fragment>
        <h2 className={'content-block'}>Üzenetküldés</h2>

        <div className={'content-block dx-card responsive-paddings'}>
          {userData && (
            <div>
              <div>
                <strong>Üzenetküldés a kiválasztott oktatóimnak</strong>
                <p>
                  Az üzenetet az alábbi oktatóidnak tudsz küldeni. Minden
                  üzenetet elküldünk a központi adminisztrációnak is, így ha
                  nekünk akarsz üzenni és nem az oktatóknak akkor megteheted
                  úgy, hogy törlöd a kijelöléseket
                </p>
              </div>
              <div className='marginTopBottom'>
                {oktatok &&
                  oktatok.length > 0 &&
                  oktatok.map((okt) => {
                    return (
                      <CheckBox
                        className='messageRecipients'
                        key={okt.OktatoID}
                        defaultValue={true}
                        text={okt.OktatoNeve}
                        onValueChanged={onRecipientsChanged}
                      />
                    );
                  })}
              </div>
              <div>
                <p>Ide írd az üzeneted:</p>
                <TextArea
                  height={150}
                  onValueChanged={onTextAreaValueChanged}
                ></TextArea>
              </div>
              <div className='marginTopBottom'>
                <Button
                  icon={'fas fa-solid fa-paper-plane'}
                  text='Elküldés'
                  width={210}
                  height={'100%'}
                  onClick={onMessageSend}
                ></Button>
              </div>
            </div>
          )}
        </div>
        <h2 className={'content-block'}>Elküldött üzenetek</h2>
        <div className={'content-block dx-card responsive-paddings'}> </div>
      </React.Fragment>
    </IntlProvider>
  );
}
