import React, { useContext, useMemo } from 'react';

import { QuizContext } from '../store/QuizContext';

const Score = () => {
  const { score } = useContext(QuizContext);
  return useMemo(() => {
    return (
      <div className='score'>
        <p>
          Pontszám: <span>{score}</span>
        </p>
      </div>
    );
  }, [score]);
};

export default Score;
