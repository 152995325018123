import React from 'react';

import 'filepond/dist/filepond.min.css';
import { FilePond, registerPlugin } from 'react-filepond';
import Moment from 'react-moment';

import FilePondPluginGetFile from 'filepond-plugin-get-file';
import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css';
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview';
import FilePondPluginPdfPreview from 'filepond-plugin-pdf-preview';

import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import { backendHOST } from '../../client-config';
import './HomeworksItem.scss';

registerPlugin(
  FilePondPluginMediaPreview,
  FilePondPluginPdfPreview,
  FilePondPluginGetFile,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode
);

const url = backendHOST + '/homework/homework';

export default function HomeWorkItem({
  id,
  file,
  title,
  description,
  diak_id,
  createad_at,
  status,
  deadline,
  jelenlet_id,
}) {
  const serverSettings = {
    url: url,
    process: {
      headers: {
        'x-auth-token': localStorage.token,
        'Access-Control-Allow-Origin': '*',
      },
      onload: (response) => response.key,
      onerror: (error) => error.message,
    },
  };
  return (
    <div className='dx-row-padding homework-item row'>
      <React.Fragment>
        <div className='hw-column main'>
          <p>
            <strong>
              {`${title} - Határidő: `}
              <Moment format='YYYY.MM.DD'>{deadline}</Moment>
            </strong>
          </p>

          <p>{description}</p>
          <p>
            <strong>Feladat letöltése</strong>
          </p>
          <FilePond
            files={file}
            allowDownloadByUrl={false}
            allowImagePreview={true}
            allowDrop={false}
            allowRevert={false}
            allowRemove={false}
            filePosterHeight={160}
            disabled={true}
            styleButtonRemoveItemPosition='right'
            maxFiles={5}
            server={serverSettings}
            name='files' /* sets the file input name, it's filepond by default */
            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
          />
        </div>
      </React.Fragment>
    </div>
  );
}
